 <template>
 <div class="password-field">
   <v-text-field
       v-bind="fieldAttrInput"
       v-bind:value="value"
       v-on:input.native="$emit('input', $event.target.value)"
       :type="showPassword ? 'text' : 'password'"
       :name="field"
       :label="localLabel"
       :rules="rules"
       outlined
       dense
       placeholder=" "
       :id="id"
       :disabled="disabled"
       :error-messages="errors"
       @input="errors = []"
   >
     <template v-slot:append>
       <v-btn class="mt-n1 mr-n2" @click="togglePassword()" tabindex="-1" icon>
         <v-icon v-if="showPassword">mdi-eye</v-icon>
         <v-icon v-if="!showPassword">mdi-eye-off</v-icon>
       </v-btn>
     </template>
   </v-text-field>
   <HelpButton :id="id"/>
 </div>
</template>
<script>
import { getRules } from "@/js/validators.js";
import { getFieldId } from "@/js/helper.js";
import HelpButton from "@/components/basic/HelpButton.vue";
export default {
  props: {
    templateContent: {},
    show: Boolean,
    fieldAttrInput: Object,
    fieldAttributes: Object,
    field: String,
    value: String
  },
  components: {HelpButton},
  data: function () {
    return {
      showPassword: false,
      errors: [],
    };
  },
  watch: {
    value() {
      this.errors = [];
    },
  },
  created() {
    let errors = [];
    this.rules.forEach((rule) => {
      let text = rule(this.value);
      if (typeof text == "string") {
        errors.push(text);
      }
    });
    this.errors = errors;
  },
  methods: {
    getRules: getRules,
    updateValue: function (value) {
      this.$emit("input", value);
    },
    togglePassword: function () {
      let showPassword = this.showPassword;
      //console.log(this.showPassword);
      this.showPassword = !showPassword;
      // console.log(this.showPassword);
    },
  },
  computed: {
    rules() {
      return getRules(
        this.fieldAttrInput,
        this.templateContent,
        this.field,
        this
      );
    },
    localValue: {
      get() {
        return this.value;
      },
      set(newVal) {
        this.$emit("input", newVal);
      },
    },
    disabled() {
      let disabled = false;
      if (
        (typeof this.templateContent != "undefined" &&
          typeof this.templateContent.disableAllInputs != "undefined" &&
          this.templateContent.disableAllInputs == true) ||
        this.fieldAttrInput.disabled == true
      ) {
        disabled = true;
      }
      return disabled;
    },
    id() {
      return getFieldId(this.fieldAttrInput, this.templateContent, this.field);
    },
    localLabel() {
      let localLabel = this.fieldAttributes.name;
      /*  if (
        typeof this.fieldAttrInput.class != "undefined" &&
        this.fieldAttrInput.class.includes("required")
      ) {
        localLabel = localLabel + "*";
      }*/
      return localLabel;
    },
  },
  // computed: {
  //   showPassword: {
  //     get: function() {
  //       return this.show;
  //     },
  //     set: function() {}
  //   }
  // }
  /* computed: {
    rules: function() {
      let rules = "";
      if (typeof this.fieldAttrInput["rules"] != "undefined") {
        rules = this.fieldAttrInput["rules"];
      } else {
        rules = "";
      }
      return rules;
    }
  }*/
};
</script>
<style scoped>
.password-field {
  display: flex;
}
.password-field >>> .help-button {
  margin-top: 2px;
}
</style>
<template>
  <div :id="id + '_container'" class="multiselect-field">
    <v-select
      :placeholder="placeholder"
      dense
      v-bind="localFieldAttrInputArray"
      :label="localLabel"
      :items="localOptions"
      outlined
      v-model="inputValue"
      item-value="value"
      :disabled="localDisabled"
      :rules="rules"
      :id="id"
      :error-messages="errors"
      @input="inputChanged()"
      :hide-details="hideDetails"
      multiple
    >
    <template v-slot:item="data" v-if="!checkBox">
        <v-list-item-content
          :id="id + '_' + data.item"
          :order-id="id + '_' + options.indexOf(data.item)"
        >
          <v-list-item-title   v-html="data.item.text"> </v-list-item-title>
        </v-list-item-content>
      </template>
    </v-select>
    <input
      type="hidden"
      :value="JSON.stringify(inputValue)"
      :name="field"
      :id="id + '_value'"
    />
    <HelpButton :id="id"/>
  </div>
</template>
<script>
import {
  getfieldAttrInput,
  getPlaceholder,
  getFieldId,
  getNoDataLabel,
} from "@/js/helper.js";
import { getSelectRules } from "@/js/validators.js";
import HelpButton from "@/components/basic/HelpButton.vue";

export default {
  components: {HelpButton},
  data: function () {
    return {
      errors: [],
      /*options: this.getItems() */
      asyncOptions: null,
      keyUpValue: "",
      //  checkError:this.checkValue(),
      //checkColor: this.checkRequiredColor(),
    };
  },
  created() {
    let errors = [];
    this.rules.forEach((rule) => {
      let text = rule(this.value);
      if (typeof text == "string") {
        errors.push(text);
      }
    });
    this.errors = errors;
  },
  watch: {
    value: function () {
      this.errors = [];
    },
  },
  methods: {
    inputChanged() {
      this.errors = [];
      this.$store.commit("setFieldDataMutated", true);
    },

    emitKeyUp(event) {
      if (
        typeof this.localFieldAttrInputArray.class != "undefined" &&
        this.localFieldAttrInputArray.class.includes("addable") &&
        typeof this.localFieldAttrInputArray.multiple == "undefined"
      ) {
        // force the calculation of value for addable on keyup
        this.keyUpValue = event.target.value;
        this.$emit("changeIsValid", true);
        if (this.value == "") {
          this.$emit("input", event.target.value);
        }
      }
      this.errors = [];
      this.$emit("keyup", event);
    },
    // onChangeCombo(event){
    //   console.log(event.value);
    //   this.$emit('combo-changed',event.value);
    // },
    getSelectRules: getSelectRules,
  },
  computed: {
    rules() {
      return getSelectRules(
        this.localFieldAttrInputArray,
        this.templateContent,
        this
      );
    },
    id() {
      return getFieldId(
        this.localFieldAttrInputArray,
        this.templateContent,
        this.field
      );
    },
    localLabel() {
      let localLabel = this.fieldAttributes.name;
      /*  if (
        typeof this.localFieldAttrInputArray.class != "undefined" &&
        this.localFieldAttrInputArray.class.includes("required") &&
        localLabel != ""
      ) {
        localLabel = localLabel + "*";
      }*/
      return localLabel;
    },

    localDisabled() {
      let localDisabled = this.disabled;

      // if (
      //   this.localFieldAttrInputArray != null &&
      //   typeof this.localFieldAttrInputArray.disabled != undefined
      // ) {
      //   localDisabled = this.localFieldAttrInputArray.disabled;
      // }

      // let disabled = false;
      if (
        (typeof this.templateContent != "undefined" &&
          typeof this.templateContent.disableAllInputs != "undefined" &&
          this.templateContent.disableAllInputs == true) ||
        (this.localFieldAttrInputArray != null &&
          typeof this.localFieldAttrInputArray.disabled != "undefined" &&
          this.localFieldAttrInputArray.disabled == true)
      ) {
        localDisabled = true;
      }
      // return disabled;

      return localDisabled;
    },
    placeholder() {
      return getPlaceholder(this.localFieldAttrInputArray);
    },
    noDataLabel() {
      let label = getNoDataLabel(this.localFieldAttrInputArray, this);
      return label;
    },
    localOptions: function () {
      let localOptions = [];
      let valueFound = false;
      if (
          (typeof this.localFieldAttrInputArray.class == "undefined" ||
              !this.localFieldAttrInputArray.class.includes("required")) &&
          typeof this.localFieldAttrInputArray.multiple == "undefined" &&
          this.fieldAttributes.ignoreEmptyAdd != true
      ) {
        localOptions.push({text: "", value: ""});
      }
      // console.log(this.fieldAttributes.associatedOption);
      if (typeof this.options != "undefined") {
        if (Array.isArray(this.options)) {
          let caller = this;
          let newText = "";
          this.options.forEach(function (value) {
            if (typeof value == "object") {
              if (Array.isArray(value)) {
                let disabledVal = false; //most probably useful in Network countries
                if (typeof value[3] != "undefined" && value[3] == "disabled") {
                  disabledVal = true;
                }
                //for celluar bands
                if (typeof caller.value != "undefined" && typeof caller.fieldAttributes.anyValue != "undefined" ) {
                  disabledVal = false;
                  if(caller.value.length==0){
                    disabledVal = false;
                  }
                  else if(caller.value.includes(caller.fieldAttributes.anyValue )){
                    if(value[0].toString()!= caller.fieldAttributes.anyValue ){
                      disabledVal = true;
                    }
                  }else{
                    if(value[0].toString()==caller.fieldAttributes.anyValue ){
                      disabledVal = true;
                    }
                  }
                }

                newText = value[1].toString();
                if(typeof caller.fieldAttributes.showValueInText!=='undefined' && caller.fieldAttributes.showValueInText===true){
                  newText = value[1].toString()+ " ("+ value[0].toString() +")";
                }
                localOptions.push({
                  text: newText,
                  value: value[0].toString(),
                  ...value,
                  disabled: disabledVal,
                  // divider: true,
                });
              }
            } else {
              localOptions.push({
                text: value.toString(),
                value: value.toString(),
                //disabled:true,
                //divider: true,
              });
            }
            if (value.toString() == caller.value.toString()) {
              valueFound = true;
            }
          });
        } else {
          if (this.options != null) {
            for (let [key, value] of Object.entries(this.options)) {
              if (typeof value == "object") {
                // console.log(value);

                localOptions.push({
                  text: value.name,
                  value: key.toString(),
                  ...value,
                });
              } else {
                localOptions.push({
                  text: value,
                  value: key.toString(),
                });
              }
              if (value.toString() == this.value.toString()) {
                valueFound = true;
              }
            }
          }
        }
      }
      if (
          typeof this.localFieldAttrInputArray.class != "undefined" &&
          this.localFieldAttrInputArray.class.includes("addable") &&
          !valueFound
      ) {
        //Adding tag into list but only new which is not already in list
        let array1 =
            typeof this.fieldAttributes.value != "undefined"
                ? this.fieldAttributes.value
                : this.fieldAttributes.associatedOption;
        let array2 = this.value;
        if (Array.isArray(array1) && Array.isArray(array2)) {
          let newArrival = array2.filter((x) => !array1.includes(x));
          newArrival.forEach(function (newVal) {
            localOptions.push({
              text: newVal,
              value: newVal,
            });
          });
        }
      }
      if (
          typeof this.fieldAttributes.sort == "undefined" ||
          this.fieldAttributes.sort == true
      ) {
        localOptions = localOptions.sort((a, b) => (a.text.toLowerCase().localeCompare(b.text.toLowerCase())/*a.text > b.text ? 1 : -1*/));
      }
      localOptions = localOptions.map((x, index) => {
        x.itemOrder = index;
        return x;
      });
      //console.log(localOptions);
      return localOptions;
    },

    options() {
      let caller = this;
      let options;
      if (this.asyncOptions != null) {
        options = this.asyncOptions;
      } else {
        if (typeof caller.fieldAttributes.associatedOption != "undefined") {
          options = caller.fieldAttributes.associatedOption;
        } else if (typeof caller.fieldAttributes.IndependentOption != "undefined") {
          options = caller.fieldAttributes.IndependentOption;
          if (options != null && !Array.isArray(options)) {
            options = Object.values(options);
          }
        } else if (typeof caller.fieldAttributes!= "undefined" && typeof caller.fieldAttributes.options != "undefined") {
          options = caller.fieldAttributes.options;
          options = options.flat(1); // flat the array of array!
         }
      }
      return options;
    },

    hasSelectionSlot() {
      return !!this.$slots.selection || !!this.$scopedSlots.selection;
    },
    inputValue: {
      get: function () {
        return this.value;
      },
      set: function (selectedValue) {
        this.$emit("input", selectedValue);
      },
    },

    localFieldAttrInputArray() {
      let localFieldAttrInputArray = {};
      if (
        typeof this.fieldAttrInput == "undefined" ||
        this.fieldAttrInput == "" ||
        this.fieldAttrInput == null
      ) {
        localFieldAttrInputArray = getfieldAttrInput(this.fieldAttributes);
      } else {
        localFieldAttrInputArray = this.fieldAttrInput;
      }
       //console.log(localFieldAttrInputArray);
      return localFieldAttrInputArray;
    },
  },
  props: {
    fieldAttrInput: {
      type: Object,
      default() {
        return null;
      },
    },
    fieldAttributes: Object,
    type: String,
    field: String,
    value: { default: "" },
    disabled: Boolean,
    templateContent: {},
    hideDetails: { type: Boolean, default: false },
    checkBox: { type: Boolean, default: false},
  },
};
</script>
<style scoped>
.multiselect-field {
  display: flex;
}
.multiselect-field >>> .help-button {
  margin-top: 2px;
}
</style>
<template>
  <div class="radio-group">
    <v-radio-group
      v-model="radios"
      :mandatory="false"
      :row="row"
      class="d-flex justify-space-around mt-n3"
      :disabled="disabled"
      :name="field"
      :rules="getSelectRules(localFieldAttrInput, result)"
      required
      :id="id"
    >
      <v-col
        style="margin-top: 2px;"
        v-if="typeof fieldAttributes.title != 'undefined'"
      >
        {{ fieldAttributes.title }}:
      </v-col>
      <v-col
        v-for="(name, value) in fieldAttributes['option']"
        v-bind:key="value"
        v-bind="localFieldAttrInput"
        n-gutters
      >
        <div class="ml-n2" style="" :id="id + '_' + value + '_container'">
          <v-radio
            :label="getRadioName(name)"
            :value="value"
            v-bind="localFieldAttrInput"
            :id="id + '_' + value"
          ></v-radio>
        </div>
      </v-col>
    </v-radio-group>
    <HelpButton :id="id"/>
  </div>
</template>
<script>
import { getSelectRules } from "@/js/validators.js";
import { getFieldId } from "@/js/helper.js";
import HelpButton from "@/components/basic/HelpButton.vue";
export default {
  components: {HelpButton},
  props: [
    "fieldAttributes",
    "value",
    "fieldAttrInput",
    "checkReadOnly",
    "field",
    "result",
  ],
  computed: {
    id() {
      return getFieldId(this.fieldAttrInput, this.result, this.field);
    },
    disabled() {
      let disabled =
        typeof this.localFieldAttrInput.disabled != "undefined"
          ? this.localFieldAttrInput.disabled
          : false;
      // console.log(this.result);
      if (
        typeof this.result != "undefined" &&
        typeof this.result.disableAllInputs != "undefined" &&
        this.result.disableAllInputs == true
      ) {
        disabled = true;
      }
      return disabled;
    },
    localFieldAttrInput() {
      let localFieldAttrInput = {};
      if (typeof this.fieldAttrInput != "undefined") {
        localFieldAttrInput = this.fieldAttrInput;
      } else if (typeof this.fieldAttributes.fieldAttrInput != "undefined") {
        localFieldAttrInput = this.fieldAttributes.fieldAttrInput;
      }
      return localFieldAttrInput;
    },
    row() {
      let row = true;
      if (typeof this.localFieldAttrInput.row != "undefined") {
        row = this.localFieldAttrInput.row;
      }
      return row;
    },
    radios: {
      get() {
        return this.value;
      },
      set(newVal) {
        this.$emit("input", newVal);
      },
    },
  },
  methods: {
    getSelectRules: getSelectRules,
    getRadioName(name) {
      let returnedName = "";
      if (
        typeof this.fieldAttributes.innerLabel == "undefined" ||
        this.fieldAttributes.innerLabel == true
      ) {
        returnedName = name;
      }
      return returnedName;
    },
  },
};
</script>

<style scoped>
.radio-group {
  display: flex;
}
.radio-group >>> .help-button {
  margin-top: 2px;
}
</style>
